import React, {useState} from "react";
import GuestLayout from "../../components/layouts/guest";
import { useNavigate } from "react-router-dom";
import LoginForm from "./login/login-form";
import OtpForm from "./login/otp";
import { useAuthStore } from "../../lib/auth/auth-store";
import ApiSupport from "../../lib/api-support";

const Login : React.FC = () => {
    const {handleLogin} = useAuthStore();

    const navigate = useNavigate();

    const [section, setSection] = useState<string>('form')

    const [email, setEmail] = useState<string>('');

    const [emailInput, setEmailInput] = useState<string>('');

    const [sectionState, setSectionState] = useState<boolean>(false);

    const [sectionStateError, setSectionStateError] = useState<string|null>(null);

    const login = async (data:{email:string, password:string}) => {
        setSectionState(true);
        
        setSectionStateError(null);
        
        const request = await new ApiSupport().post('/login', {email: data.email, password: data.password});

        setSectionState(false);

        if ( request === 'error' ){
            setSectionStateError('Wrong username or password.');
            return;
        }

        const response = JSON.parse(request);

        if ( ! response.data?.success ){
            setSectionStateError('Error processing request.');
            return;
        }

        if ( ! response.data.success ){
            setSectionStateError('Wrong username or password.');
            return;
        }

        const [localPart, domain] = data.email.split('@');

        let limit = localPart.length < 4 ? localPart.length - 1 : 4;

        const maskedLocalPart = localPart.slice(0, limit).replace(/./g, '*') + localPart.slice(limit);
     
        const maskedEmail = `${maskedLocalPart}@${domain}`;

        setEmail(maskedEmail);

        setEmailInput(data.email);
        
        setSection('otp')
    }

    const verifyOtp = async (otp:string) => {
        setSectionState(true);
        
        setSectionStateError(null);
        
        const request = await new ApiSupport().post('/verifyOtp', {email: emailInput, otp});

        setSectionState(false);

        if ( request === 'error' ){
            setSectionStateError('Invalid OTP code inputted.');
            return;
        }

        const response = JSON.parse(request);

        if ( ! response.data?.success ){
            setSectionStateError('Error processing request.');
            return;
        }

        if ( ! response.data?.success ){
            setSectionStateError('Invalid OTP code inputted');
            return;
        }

        const user = JSON.parse(request).data.user;

        handleLogin({name:`${user.first_name} ${user.last_name}`, email: user.email, token: user.token});
    }

    const resendOtp = async () => {        
        //setSectionStateError(null);

       // const request = await 
        new ApiSupport().post('/resendOtp', {email: emailInput});

        // if ( request === 'error' ){
        //     setSectionStateError('Could not resend OTP, try again');
        //     return false;
        // }

        return true;
    }
    
    return(
        <GuestLayout>
            {section === 'form' && <LoginForm loginError={sectionStateError} loginState={sectionState} onSubmitForm={login}/>}
            {section === 'otp' && 
            <OtpForm 
                onSubmitForm={verifyOtp} 
                changeSection={setSection} 
                resendOtp={resendOtp}
                email={email} 
                otpError={sectionStateError}
                otpState={sectionState}
            />}
        </GuestLayout>
    );
}

export default Login;